const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://golam.dev',
  title: 'golam.dev',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Golam Tahsin',
  role: 'Full Stack Engineer',
  description:
    'Eager to harness my expertise in full-stack development and problem-solving skills to deliver robust software solutions. Committed to driving efficiency and innovation while contributing to meaningful projects and business growth.',
  resume: 'https://drive.google.com/uc?export=download&id=1KGKx4WyaR6bw4ddofxnC_vQPXXBs_Im0',
  social: {
    linkedin: 'https://www.linkedin.com/in/golamtahsin',
    github: 'https://github.com/golamt313',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Voting App (INPR.)',
    description:
      'A voting application built on ReactJS and Firebase to allow real time voting and data analysis.',
    stack: ['React', 'Firebase', 'SASS'],
    sourceCode: 'https://github.com/golamt313/voting',
    livePreview: 'https://election-2024-a034c.web.app',
  },
  {
    name: 'T&T Graphics Printing',
    description:
      'Web to print solution with full ecommerce capabilities. Built on WordPress with WooCommerce.',
    stack: ['HTML/CSS', 'JavaScript', 'WordPress', 'WooCommerce'],
    // sourceCode: 'https://github.com',
    livePreview: 'https://tandtgraphics.com',
  },
  {
    name: 'OMS',
    description:
      'Bootstrapped order management system built with JavaScript and integrated with Firebase database.',
    stack: ['HTML/CSS', 'JavaScript', 'Firebase', 'Bootstrap'],
    sourceCode: 'https://github.com/golamt313/oms',
    livePreview: 'https://golamt313.github.io/oms',
  },
]

const skills = [
  'HTML',
  'CSS',
  'JavaScript',
  'PHP',
  'Java',
  'MySQL',
  'React',
  'SASS',
  'Material UI',
  'Git',
  'Bootstrap',
  'Android Studio',
  'XCode'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'tahsingolam@gmail.com',
}

export { header, about, projects, skills, contact }
